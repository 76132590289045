<template>
	<div>
		<SelectContract />
		<SelectContractLabel />
		<PaymentsList v-if="selectedContract.aquisicao_escritura === 'Não'" />
	</div>
</template>

<script>
import SelectContract from '@/views/components/selectContract.vue'
import SelectContractLabel from '@/views/components/selectContractLabel.vue'
import PaymentsList from './components/PaymentsList.vue'
import store from '@/store'
export default {
	components: {
		SelectContract,
		SelectContractLabel,
		PaymentsList,
	},

	computed: {
		selectedContract() {
			return store.getters['contracts/getSelectedContract']
		},
	},
}
</script>
