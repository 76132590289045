<template>
	<div v-if="getSelectedContract && items">
		<b-form-checkbox
			class="mb-2"
			v-if="getSelectedContract.situacao == 40"
			@change="listPaidPayments()"
		>
			Exibir Parcelas Pagas
		</b-form-checkbox>
		<b-table striped responsive :items="items" :fields="fields" class="d-none d-md-block">
			<template #cell(situacao_desc)="data">
				<b-badge :variant="variantPayment(data.item.situacao_desc)">
					{{ data.item.situacao_desc }}
				</b-badge>
			</template>

			<template #cell(acoes)="data">
				<span class="d-flex justify-content-center">
					<b-button
						variant="primary"
						class="mr-1"
						style="padding: 5px"
						v-if="data.item.boleto_link"
						:href="data.item.boleto_link"
						target="_blank"
					>
						<feather-icon size="15" icon="FileTextIcon" />
						Boleto
					</b-button>

					<b-button
						class="mr-1"
						variant="primary"
						@click="copyUPC(data.item.ipte)"
						v-if="data.item.ipte"
						style="padding: 5px"
						v-b-tooltip.hover.top="'Copiar Código de Barras'"
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="18"
							height="18"
							fill="currentColor"
							class="bi bi-upc"
							viewBox="0 0 16 16"
						>
							<path
								d="M3 4.5a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-7zm3 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7z"
							/>
						</svg>
						Copiar
					</b-button>

					<b-button
						variant="primary"
						style="padding: 5px"
						@click="selectPayment(data.item)"
					>
						<feather-icon size="15" icon="InfoIcon" />
						Info
					</b-button>
				</span>
			</template>
		</b-table>

		<b-table hover :items="items" :fields="fieldsSm" class="d-md-none">
			<template #cell(descricao)="data">
				<div
					class="m-2 d-flex justify-content-around align-items-center"
					v-on:click="selectPayment(data.item)"
				>
					<div>
						<span style="font-size: 0.99em"
							>{{ data.item.descricao }} - {{ data.item.parcela }}</span
						>
						<p class="mt-1" style="font-size: 1.85em">
							<span v-if="data.item.valor_pago">{{
								formatPriceTable(data.item.valor_pago)
							}}</span>
							<span v-else>{{ formatPriceTable(data.item.valor_original) }}</span>
						</p>
						<span style="font-size: 0.99em"
							>Vence em {{ formatDate(data.item.data_vencimento) }}</span
						>
					</div>

					<b-badge :variant="variantPayment(data.item.situacao_desc)">
						{{ data.item.situacao_desc }}
					</b-badge>

					<feather-icon icon="ChevronRightIcon" size="25" />
				</div>
			</template>
		</b-table>

		<b-row>
			<b-spinner class="my-3 mx-auto" v-if="isLoading" />
		</b-row>
	</div>
</template>

<script>
import {
	BTable,
	BSpinner,
	BBadge,
	BFormCheckbox,
	BButton,
	BRow,
	VBTooltip,
} from 'bootstrap-vue'
import { getPayments } from '@/services/contract'
import store from '@/store'
import formatValue from '@/utils/formatValue.js'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
	components: {
		BTable,
		BSpinner,
		BBadge,
		BFormCheckbox,
		BButton,
		BRow,
	},
	directives: {
		'b-tooltip': VBTooltip,
	},
	mixins: [formatValue],
	data() {
		return {
			isLoading: false,
			checkedPaidPayments: false,
			items: [],
			fields: [
				{
					key: 'descricao',
					label: 'Tipo',
					thClass: 'text-center',
					tdClass: 'text-center',
				},
				{
					key: 'parcela',
					label: 'Parcela',
					thClass: 'text-center',
					tdClass: 'text-center',
				},
				{
					key: 'data_vencimento',
					label: 'Vencimento',
					thClass: 'text-center',
					tdClass: 'text-center',
					formatter: value => {
						return this.formatDate(value)
					},
				},
				{
					key: 'valor_pago',
					label: 'Valor',
					thClass: 'text-center',
					tdClass: 'text-center',
					formatter: (value, key, item) => {
						if (item.valor_pago) {
							return this.formatPriceTable(item.valor_pago)
						} else {
							return this.formatPriceTable(item.valor_original)
						}
					},
				},
				{
					key: 'situacao_desc',
					label: 'Situação',
					thClass: 'text-center',
					tdClass: 'text-center',
				},
				{
					key: 'acoes',
					label: 'Ações',
					thClass: 'text-center',
					tdClass: 'text-center',
				},
			],
			fieldsSm: [
				{
					key: 'descricao',
					label: 'Parcelas',
					thClass: 'text-center',
					tdClass: 'text-left',
				},
			],
		}
	},

	computed: {
		getSelectedContract() {
			return store.getters['contracts/getSelectedContract']
		},
	},

	mounted() {
		this.getSelectedContract ? this.getPayment() : null
	},

	methods: {
		getPayment: async function() {
			this.items = []
			this.isLoading = true
			let { data } = await getPayments(this.$apolloProvider.defaultClient)
			this.items = data ? data : []

			if (
				this.checkedPaidPayments != true &&
				this.getSelectedContract.situacao == 40 &&
				this.items
			) {
				this.items = this.items.filter(item => item.situacao_desc != 'Pago')
			}

			this.isLoading = false
		},

		copyUPC(upc) {
			navigator.clipboard.writeText(upc)
			this.$toast({
				component: ToastificationContent,
				props: {
					title: 'Código de barras copiado!',
					icon: 'CheckIcon',
					variant: 'success',
				},
			})
		},

		listPaidPayments: function() {
			this.checkedPaidPayments = !this.checkedPaidPayments
			this.getPayment()
		},

		variantPayment: function(descricao) {
			if (descricao == 'Pago') {
				return 'light-success'
			} else if (descricao == 'A Vencer') {
				return 'light-info'
			} else if (descricao == 'A Reajustar') {
				return 'light-warning'
			} else {
				return 'light-danger'
			}
		},

		showPaidPayments: function() {
			if (this.checkedPaidPayments) {
				this.listPaidPayments()
			} else {
				this.getPayment()
			}
			this.checkedPaidPayments = !this.checkedPaidPayments
		},

		selectPayment: function(data) {
			store.commit('contracts/setSelectedPayment', data)
			router.push({ name: 'parcela', params: { data } })
		}
	},

	watch: {
		getSelectedContract: {
			handler: function() {
				this.checkedPaidPayments = false
				this.getSelectedContract ? this.getPayment() : null
			},
		},
	},
}
</script>
